import { useParams, Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthProvider';
import { useQuery } from "@tanstack/react-query";
import StatusBadge from './StatusBadge';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import LoadingView from './Loading-View';
import Container from './Container';
import BackButton from './components/back-arrow';
import { DatePickerWithRange } from './DateRangePicker';
import { DateRange } from "react-day-picker"


export default function AdDetails() {
  const params = useParams();
  const { gqlClient } = useContext(AuthContext);

  const getAdById = async () => {
    const document = `
      query Query($id: ID!) {
        myAd(id: $id) {
          id
          name
          status
          statusDetails
          target {
            url
          }
          boards {
            name
            image {
              url
            }
            description
          }
          image {
            url
          }
          video {
            url
          }
        }
      }
    `;

    const variables = {
      id: params.id
    }

    return gqlClient.current?.request({ document, variables })
  };

  const { data: resp, isPending, error } = useQuery<Record<string, any>>({
    queryKey: [`getAdById-${params.id}`], queryFn: getAdById, retry: 0
  });

  if (isPending) {
    return <LoadingView />
  }

  if (error) {
    alert(error);
    return null;
  }

  const ad = (resp as any).myAd;

  const boardComps: any[] = [];
  (ad as any)?.boards.forEach((item: any) => {

    boardComps.push(
      <BoardRow
        data={item}
        key={item.id}
      />
    );
  });

  return(
    <Container>
      <BackButton to="/dashboard" label="Back to Dashboard" />

      <div className="mb-10">
        <div className="mt-2">
          <div className="overflow-hidden">
            <div className="font-medium text-xl">{ ad.name }</div>
            <p className="overflow-hidden text-ellipsis text-nowrap">
              <a href={ad.target?.url} target="_blank" rel="noreferrer" className="text-primary text-sm">{ ad.target?.url }</a>
            </p>
          </div>

          <div className="flex space-x-2 mt-2">

            <a target="_blank" href={ad.image?.url} rel="noreferrer">
              <div
                className="w-28 aspect-adv rounded-lg overflow-hidden bg-cover bg-center bg-no-repeat"
                style={{backgroundImage: `url(${ad.image?.url})`}}>
              </div>
            </a>

            { !!ad.video?.url && (
            <div
              className="w-28 aspect-adv rounded-lg overflow-hidden bg-cover bg-center bg-no-repeat">
              <video className="h-full" muted controls>
                <source src={ad.video?.url} />
              </video>

            </div>
            )}
          </div>


            <div className="mt-3">
              <StatusBadge label={ad.status} />
            </div>

          <div className="space-y-2 grow mb-4">
            {boardComps}
          </div>

        </div>

        {
          (ad.status === 'Rejected' && ad.statusDetails) && (
            <div className='pt-6'>
              <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                <p>{ad.statusDetails}</p>
              </div>
            </div>
          )
        }

      </div>

      <div className="font-semibold pb-4">QR Scans (By Date)</div>
      <AdStats ad={ad} />

      <AdActions ad={ad} />

    </Container>
  );
}

function AdStats(props: any) {
  const { gqlClient } = useContext(AuthContext);
  const { ad }  = props;

  const binTypes = {
    day: 'day',
    hour: 'hour',
  };

  // const [start] = useState(oneMonthAgo);
  // const [end] = useState(today);

  const [bin] = useState(binTypes.hour);

  const today = new Date();
  const lastMonth = new Date();
  lastMonth.setMonth(today.getMonth() - 1);

  const [date, setDate] = useState<DateRange>({
    from: lastMonth,
    to: today
  });

  const getAdStats = async (start: Date, end: Date, bin: string) => {
    const document = `
      query Query($id: ID!, $start: AWSDateTime!, $end: AWSDateTime!, $bin: String) {
        adStats(id: $id, start: $start, end: $end, bin: $bin) {
          data
        }
      }
    `;

    const variables = {
      id: ad.id,
      start: start.toISOString(),
      end: end.toISOString(),
      bin
    };

    return gqlClient.current?.request({ document, variables })
  };

  const start = date?.from || lastMonth;
  const end = date?.to || today;

  const { data: resp, isPending, error, refetch } = useQuery<Record<string, any>>({
    queryKey: [`getAdStats-${ad.id}`], queryFn: () => getAdStats(start, end, bin), retry: 0
  });

  useEffect(() => {
    refetch();
  }, [date]);

  if (isPending) {
    return <LoadingView />
  }

  if (error) {
    console.log(error);
    // alert(error);
    return null;
  }

  const data = JSON.parse((resp as any).adStats.data);

  const colors = {
    'Work in Progress, Costa Mesa': '#8048b7',
    'Nook Coffee, Costa Mesa': '#4fb06e',
    'Neat Coffee, Costa Mesa': '#00aed1',
  } as any;

  const bars: Array<any> = [];
  data?.board_names?.forEach((board_name: string, index: number) => {
    bars.push(<Bar dataKey={board_name} name={board_name} key={board_name} fill={colors[board_name]} stackId="a" />);
  });

  return (
    <div>

      <div className='pb-5'>
        <DatePickerWithRange date={date} setDate={setDate} />
      </div>

      { !data?.items?.length ? (
        <div className='py-10'>
          <div className="font-medium text-gray-500 text-center text-sm mb-1">No data for range</div>
          <div className="font-medium text-center text-sm"><span className='font-bold'>{start.toDateString()}</span> to <span className='font-bold'>{end.toDateString()}</span></div>
        </div>
      ) : (
        <ResponsiveContainer width={'100%'} height={400}>
          <BarChart
            data={data?.items}
            margin={{
              right: 0,
              left: 10,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" />

            <XAxis dataKey="name" tickFormatter={(date) => {
              return new Date(date).toLocaleDateString('en-us', { weekday: undefined, hour:"numeric", minute: "numeric", year:undefined, month:"numeric", day:"numeric"})
              if (bin === 'hour') {
                return new Date(date).toLocaleDateString('en-us', { weekday: undefined, hour:"numeric", year:undefined, month:"numeric", day:"numeric"})
              }
              return new Date(date).toLocaleDateString('en-us', { weekday: undefined, year:"numeric", month:"numeric", day:"numeric"})
            }}
                angle={300}
                textAnchor="end"
                height={120}
              />

            <YAxis
              tickFormatter={(count) => {
                return Math.round(count).toString()
              }}
              allowDecimals={false}
              width={20}
            />

            <Tooltip
              labelFormatter={(date) => {
                if (bin === 'hour') {
                  return new Date(date).toLocaleDateString('en-us', { weekday: 'short', hour:"numeric", year:undefined, month:"numeric", day:"numeric"})
                }
                return new Date(date).toLocaleDateString('en-us', { weekday: undefined, year:"numeric", month:"numeric", day:"numeric"})
              }}
            />
            <Legend />
            { bars }
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

function AdActions(props: any) {
  const { ad } = props;

  return (
    <div className="mt-10 flex justify-end py-4 px-4 bg-gray-50 rounded-md">
      <Link className="text-primary font-semibold text-sm py-2 px-4" to={`/dashboard/ads/${ad.id}?back=ad-details`}>Edit Ad</Link>
    </div>
  );
}


function BoardRow(props: any) {
  const { data } = props;

  return (
    <div className="rounded-lg">
      <label className="flex flex-row items-start py-2 grow">
        <div className="w-16 h-16 overflow-hidden rounded-lg flex-shrink-0">
          <div
            className='w-full h-full bg-cover bg-center bg-no-repeat'
            style={{backgroundImage: `url(${data.image?.url})`}}>
          </div>
        </div>

        <div className="pl-4 grow">
          <div className="font-bold text-sm">{ data.name }</div>
          <div className="text-xs pt-2 max-w-xs">{ data.description }</div>
        </div>
      </label>
    </div>
  )
}

// <Line type="monotone" dataKey="count" name="QR Scans" stroke="#8884d8" dot={{ r: 5 }} activeDot={{ r: 8 }} strokeWidth={2} />
